import React from 'react';
import { toast } from 'react-toastify';
import Button from '../Button/Button';
import GhostButton from '../GhostButton/GhostButton';
import Modal from '../Modal/Modal';
import type { ChangeCodeType } from '../../types/codeTypes';
import type { ChangeCouponType } from '../../types/couponTypes';

type PropsType = {
  onClick?: () => void;
  className?: string;
  onCloseModal?: () => void;
  rowInfo: ChangeCodeType | ChangeCouponType;
};

const DeleteCodeModal: React.FC<PropsType> = (props) => {
  const currentCode = props.rowInfo.code || '';

  const onDelete = async () => {
    try {
      if (props?.onClick) {
        props?.onClick();
      }
      toast.success(`${currentCode} was deleted`);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Modal>
      <div className="styled-modal__container__form__input">
        <h1>{currentCode}</h1>
      </div>
      <h1 className="styled-modal__container__text">Are you sure you want to delete this code?</h1>
      <div className="styled-modal__container__form">
        <div className="styled-modal__container__buttons-area">
          <GhostButton onClick={props?.onCloseModal}>
            <p className="text-3">Cancel</p>
          </GhostButton>
          <Button onClick={onDelete}>
            <h4>Delete</h4>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCodeModal;
