import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type OptionsType = {
  label: string;
  value: string | undefined;
};

type PropsType = {
  nameInput: string;
  title: string;
  values: string[];
  options: OptionsType[];
  onChange: (value: string[]) => void;
  error?: string | string[] | never[];
  isTouched?: boolean | never[];
  onBlur?: () => void;
};

export default function MultipleSelectCheckmarks({
  nameInput,
  title,
  values,
  options,
  onChange,
  error,
  isTouched,
  onBlur,
}: PropsType) {
  const [selectedValues, setSelectedValues] = React.useState<string[]>(values);
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedValues(selected);
    onChange(selected);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {title ? <p className="label-text styled-input__label">{title}</p> : null}
      <FormControl sx={{ width: '100%', borderRadius: '10px' }} error={isTouched && Boolean(error)}>
        <InputLabel id={`${nameInput}-label`}>{nameInput}</InputLabel>
        <Select
          sx={{ borderRadius: '10px', background: '#F0F0F0' }}
          labelId={`${nameInput}-label`}
          id={`${nameInput}`}
          multiple
          value={selectedValues}
          onChange={handleChange}
          onBlur={onBlur}
          input={<OutlinedInput label={nameInput} />}
          renderValue={(selected) => selected.map((value) => options
            .find((option) => option.value === value)?.label).join(', ')}
          MenuProps={MenuProps}
        >
          {options.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              <Checkbox checked={selectedValues.includes(item.value as string)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {isTouched && error && <Box component="small" sx={{ color: 'red', marginTop: '5px' }}>{error}</Box>}
      </FormControl>
    </Box>
  );
}
