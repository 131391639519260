/* eslint-disable */

import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Checkbox, Box, TextField, FormControlLabel, MenuItem, Grid } from '@mui/material';
import Button from '../Button';
import GhostButton from '../GhostButton';
import Modal from '../Modal';
import Input from '../Input/Input';
import MultipleSelectCheckmarks from '../MultipleSelectCheckmarks';
import { addCouponModalSchema } from '../../types/validationSchemes';
import config from '../../config';
import { AddCouponType } from '../../types/couponTypes';

type PropsType = {
  onClick?: (values: AddCouponType) => void;
  className?: string;
  onCloseModal?: () => void;
};

const options = config.stripProducts.map((item) => ({ label: item.name, value: item.id }));

const AddCouponModal: React.FC<PropsType> = (props) => {
  const formik = useFormik({
    initialValues: {
      code: '',
      percent_off: 10,
      max_redemptions: 1,
      products: [],
      expires_at: 
        new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0],
      isMaxRedemption: false,
      duration: 'once',
      duration_in_months: 1,
      first_time_transaction: false,
    },
    validationSchema: addCouponModalSchema,
    validateOnMount: false,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        if (props?.onClick) {
          const { duration_in_months, duration, isMaxRedemption, max_redemptions, code, ...rest } = values;
          let payload : AddCouponType = {
            duration,
            code: code.toUpperCase(),
            ...rest,
          }
          if(duration === 'repeating') { payload.duration_in_months = duration_in_months }
          if(isMaxRedemption) { payload.max_redemptions = max_redemptions }
          props?.onClick(payload)
          toast.success(`Coupon ${code.toUpperCase()} has been created successfully!`);
          if (props?.onCloseModal) { props?.onCloseModal() }
        }
      } catch (err) {
        toast.error('An error occurred while creating the coupon');
      }
    },
  });

  return (
    <Modal>
      <Box className="styled-modal__container__form__input">
        <h3>Add New Discount Coupon</h3>
      </Box>
      <h1 className="styled-modal__container__text">Fill in the details to create a coupon</h1>
      <form onSubmit={formik.handleSubmit} className="styled-modal__container__form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              nameInput="code"
              title="Coupon Code"
              placeHolder="Enter Coupon Code"
              type="string"
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.errors.code}
              isTouched={formik.touched.code}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              nameInput="percent_off"
              title="Discount %"
              placeHolder="Discount %"
              type="number"
              step="1"
              min="0"
              onChange={formik.handleChange}
              value={formik.values.percent_off}
              error={formik.errors.percent_off}
              isTouched={formik.touched.percent_off}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              nameInput="expires_at"
              title="Expiration Date (mm/dd/yyyy)"
              placeHolder="Expiration Date"
              type="date"
              onChange={formik.handleChange}
              value={formik.values.expires_at}
              error={formik.errors.expires_at}
              isTouched={formik.touched.expires_at}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultipleSelectCheckmarks
              nameInput="Products"
              title="Applied at"
              options={options}
              values={formik.values.products}
              onChange={(selected) => formik.setFieldValue('products', selected)}
              error={formik.errors.products}
              isTouched={formik.touched.products}
              onBlur={() => formik.setFieldTouched('products', true)}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.first_time_transaction}
                  onChange={(e) => {
                    formik.setFieldValue('first_time_transaction', e.target.checked);
                  }}
                  name="first_time_transaction"
                />
              }
              label="Apply only to the first transaction"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ borderRadius: '10px', background: '#F0F0F0', mb: 1 }}
              select
              label="Duration"
              value={formik.values.duration}
              onChange={(e) => formik.setFieldValue('duration', e.target.value)}
              onBlur={() => formik.setFieldTouched('duration', true)}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: { borderRadius: '10px', background: '#F0F0F0' }
              }}
            >
              <MenuItem value="once">Once</MenuItem>
              <MenuItem value="forever">Forever</MenuItem>
              <MenuItem value="repeating">Repeated</MenuItem>
            </TextField>
            {formik.values.duration === 'repeating' && (
              <Input
                nameInput="duration_in_months"
                title="Duration in Months"
                placeHolder="Enter number of months"
                type="number"
                step="1"
                min="1"
                onChange={formik.handleChange}
                value={formik.values.duration_in_months}
                error={formik.errors.duration_in_months}
                isTouched={formik.touched.duration_in_months}
                onBlur={formik.handleBlur}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              sx={{ mb: 1 }}
              control={
                <Checkbox
                  checked={formik.values.isMaxRedemption}
                  onChange={(e) => {
                    formik.setFieldValue('isMaxRedemption', e.target.checked);
                  }}
                  name="isMaxRedemption"
                />
              }
              label="Limit the total number of times this coupon can be redeemed"
            />
            {formik.values.isMaxRedemption && (
              <Input
                nameInput="max_redemptions"
                title="Max Redemptions"
                placeHolder="Max Redemptions"
                type="number"
                step="1"
                min="1"
                onChange={formik.handleChange}
                value={formik.values.max_redemptions}
                error={formik.errors.max_redemptions}
                isTouched={formik.touched.max_redemptions}
                onBlur={formik.handleBlur}
              />
            )}
          </Grid>
        </Grid>
        <Box className="styled-modal__container__buttons-area" mt={3}>
          <GhostButton onClick={props?.onCloseModal}>
            <p className="text-3">Cancel</p>
          </GhostButton>
          <Button type="submit">
            <h4>Create Coupon</h4>
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default AddCouponModal;
