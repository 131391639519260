import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTableBodyRow from './TableBodyRowCouponAdmin.styles';
import GhostButton from '../GhostButton/GhostButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import DeleteModal from '../DeleteModal';
import userThunk from '../../store/user/userThunk';
import type { CouponType, ChangeCouponType } from '../../types/couponTypes';

type PropsType = {
  item: CouponType;
  className?: string;
};

const TableBodyRowCouponAdmin: React.FC<PropsType> = (props) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.userSlice.filter);
  const [rowCouponInfo, setRowCouponInfo] = useState<ChangeCouponType>({
    couponId: '',
    code: '',
  });

  const onChangeCoupon = async () => {
    try {
      const code: ChangeCouponType = {
        couponId: rowCouponInfo.couponId,
        code: rowCouponInfo.code,
      };

      await dispatch(userThunk.deleteCoupon(code)).unwrap();
      await dispatch(userThunk.getAdminCoupons(filter)).unwrap();
    } catch (error) {
      toast.error(error);
    } finally {
      onClickCancelChangeRowCouponModal();
    }
  };

  const onClickCancelChangeRowCouponModal = () => {
    setIsOpenDeleteModal(false);
    setRowCouponInfo({ couponId: '', code: '' });
  };

  const onOpenDeleteRowCouponModal = (item: CouponType) => {
    setIsOpenDeleteModal(true);
    setRowCouponInfo({ couponId: item.couponId, code: item.code });
  };

  const convertUnixTimeStampToDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const { item, className = '' } = props;

  const orderValueArray = useMemo(() => {
    return [
      { content: item.index },

      { content: item.code },

      { content: `${item.discount}%` },

      { content: item.max_redemptions ? `${item.times_redeemed}/${item.max_redemptions}` : item.times_redeemed },

      {
        content: (
          <Chip
            sx={{ textTransform: 'capitalize' }}
            label={
              item.duration === 'repeating'
                ? `${item.duration_in_months} Month${item.duration_in_months === 1 ? '' : 's'}`
                : item.duration
            }
          />
        ),
      },

      { content: item.expire_at ? convertUnixTimeStampToDate(item.expire_at) : '' },

      {
        content: (
          <GhostButton className="secondary">
            <DeleteIcon color="error" onClick={() => onOpenDeleteRowCouponModal(item)} />
          </GhostButton>
        ),
      },

    ];
  }, [item]);

  return (
    <>
      <StyledTableBodyRow className={className}>
        {orderValueArray.map((item, index) => (
          <td key={index}>
            <div>
              {item.content}
            </div>
          </td>
        ))}
      </StyledTableBodyRow>
      {
        isOpenDeleteModal ? (
          <tr>
            <td>
              <DeleteModal
                onClick={onChangeCoupon}
                onCloseModal={onClickCancelChangeRowCouponModal}
                rowInfo={rowCouponInfo}
              />
            </td>
          </tr>
        ) : null
      }
    </>
  );
};

export default TableBodyRowCouponAdmin;
