export const codeAdminTableHead = [
  {
    title: '#',
    name: 'couponId',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Discount Code',
    name: 'code',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'One Claim Per User',
    name: 'oneClaimPerUser',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Discount',
    name: 'discount',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Expires',
    name: 'expire_at',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Action',
    name: 'action',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
];

export const couponAdminTableHead = [
  {
    title: '#',
    name: 'index',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Promo Code',
    name: 'code',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Discount',
    name: 'discount',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Times Redeemed',
    name: 'times_redeemed',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Duration',
    name: 'duration',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: true,
  },
  {
    title: 'Expires',
    name: 'expire_at',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Action',
    name: 'action',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
];

export const couponStatusFilter = [
  {
    title: 'Show all',
    value: '',
  },
  {
    title: 'Once',
    value: 'once',
  },
  {
    title: 'Forever',
    value: 'forever',
  },
  {
    title: 'Repeated',
    value: 'repeating',
  },
];
