/* eslint-disable no-nested-ternary */
import React from 'react';
import StyledTableBody from './TableBody.styles';
import type { FilterType } from '../../types/filterTypes';
import type { OrderType } from '../../types/orderTypes';
import TableBodyRow from '../TableBodyRow/TableBodyRow';
import { useAppSelector } from '../../store/hooks';
import TableBodyRowOrderAdmin from '../TableBodyRowOrderAdmin';
import TableBodyRowUserAdmin from '../TableBodyRowUserAdmin';
import TableBodyRowCodeAdmin from '../TableBodyRowCodeAdmin';
import type { PaymentType, UserType } from '../../types/userTypes';
import type { CodeType } from '../../types/codeTypes';
import { useLocationName } from '../../utils/checkLocationHelper';
import TableBodyRowPayment from '../TableBodyRowPayment/TableBodyRowPayment';
import type { CouponType } from '../../types/couponTypes';
import TableBodyRowCouponAdmin from '../TableBodyRowCouponAdmin';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
  mainArray: Array<OrderType | UserType | PaymentType | CodeType | CouponType>;
  length: number;
};

const TableBody: React.FC<PropsType> = (props) => {
  const isAdminUser = useAppSelector((state) => state.userSlice.user.isSuperUser);
  const user = useAppSelector((state) => state.userSlice.user);
  const locationName = useLocationName();
  const isOrderPage = locationName === 'orders';
  const isCodesPage = locationName === 'codes';
  const isPaymentsPage = locationName === 'payments';
  const isCouponPage = locationName === 'coupons';

  return (
    <StyledTableBody>
      {props.mainArray?.length
        ? props.mainArray.map((item, index) => (
          isAdminUser ? (
            isOrderPage ? (
              <TableBodyRowOrderAdmin key={index} item={item as OrderType} className="text-2" user={user} />
            ) : isCodesPage ? (
              <TableBodyRowCodeAdmin key={index} item={item as CodeType} className="text-2" />
            ) : isCouponPage ? (
              <TableBodyRowCouponAdmin key={index} item={{ index: index + 1, ...item } as CouponType} className="text-2" />
            ) : (
              <TableBodyRowUserAdmin key={index} item={item as UserType} className="text-2" />
            )
          ) : (
            isPaymentsPage ? (
              <TableBodyRowPayment key={index} item={item as PaymentType} className="text-2" />
            ) : (
              <TableBodyRow key={index} item={item as OrderType} className="text-2" />
            )
          )
        )) : (
          <tr>
            <td colSpan={props.length} className="styled-table-body__one-column-td">
              <div className="styled-table-body__one-column-td__no-data-info text-4">No data available in table</div>
            </td>
          </tr>
        )
      }
    </StyledTableBody>
  );
};

export default TableBody;
