import { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { useLocation } from 'react-router-dom';
import SubscriptionCard from './components/SubscriptionCard';
import config from '../../config';
import { paymentApi } from '../../api/services/userApi';
import { useAppSelector } from '../../store/hooks';
import PageTitle from '../../components/PageTitle';
import PromoCodeModal from './components/PromoCodeModal';
import track from '../../utils/googleAnalyticsEvents';
import PlanSlider from '../../components/PlanSlider';

type PlanType = {
  id?: string | null;
  name: string;
  price: string;
  description?: string;
  features: string[];
};

function SubscriptionPage() {
  const userSubscription = useAppSelector((state) => state.userSlice.user.subscription);
  const subscriptionPlans = Object.values(config.subscriptionPlans);
  const promoCodePlans = config.promoCodePlans as Record<string, PlanType>;
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const onSubscription = async (priceId: string) => {
    try {
      const res = await paymentApi.checkoutSubscription(
        priceId,
      );
      if (res.status === StatusCodes.OK) {
        const link = document.createElement('a');
        link.href = res.data.data?.session.url || '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (sessionId) {
      track.subscriptionEvent();
    }
  }, [sessionId]);

  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <PageTitle
      title="Subscriptions"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        gap: 2,
      }}
      >
        <Box
          display={userSubscription && promoCodePlans[userSubscription.type] ? 'none' : 'block'}
        >
          <Button
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="contained"
          onClick={handleOpen}
          >
            Enter Promo Code
          </Button>
          <PromoCodeModal open={openModal} handleClose={handleClose} onSubmit={onSubscription} />
        </Box>
      </PageTitle>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <PlanSlider />
        </Grid>
        {subscriptionPlans.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            lg={6}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <SubscriptionCard
              {...item}
              onClick={onSubscription}
              status={
                item.id === userSubscription?.planId
                  ? userSubscription?.status
                  : undefined
              }
              disabled={
                item.id === null ||
                (userSubscription?.planId !== null && item.id !== userSubscription?.planId)
              }
              manageSubscription={
                (userSubscription?.planId !== null && item.id === userSubscription?.planId)
              }
            />
          </Grid>
        ))}
        {
          userSubscription && promoCodePlans[userSubscription.type] && (
          <Grid
            item
            xs={12}
            lg={6}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <SubscriptionCard
              {...promoCodePlans[userSubscription.type]}
              onClick={onSubscription}
              status={
                userSubscription?.status
              }
              manageSubscription
            />
          </Grid>
          )
        }
      </Grid>
    </Box>
  );
}

export default SubscriptionPage;
