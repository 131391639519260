import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTableBodyRow from './TableBodyRowCodeAdmin.styles';
import type { CodeType, ChangeCodeType } from '../../types/codeTypes';
import GhostButton from '../GhostButton/GhostButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import DeleteModal from '../DeleteModal';
import userThunk from '../../store/user/userThunk';

type PropsType = {
  item: CodeType;
  className?: string;
};

const TableBodyRowCodeAdmin: React.FC<PropsType> = (props) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.userSlice.filter);
  const [rowCodeInfo, setRowCodeInfo] = useState<ChangeCodeType>({
    couponId: 0,
    code: '',
  });

  const onChangeCode = async () => {
    try {
      const code: ChangeCodeType = {
        couponId: rowCodeInfo.couponId,
        code: rowCodeInfo.code,
      };

      await dispatch(userThunk.deleteCode(code)).unwrap();
      await dispatch(userThunk.getAdminCodes(filter)).unwrap();
      await dispatch(userThunk.getAdminCodeCount()).unwrap();
    } catch (error) {
      toast.error(error);
    } finally {
      onClickCancelChangeRowCodeModal();
    }
  };

  const onClickCancelChangeRowCodeModal = () => {
    setIsOpenDeleteModal(false);
    setRowCodeInfo({ couponId: 0, code: '' });
  };

  const onOpenDeleteRowCodeModal = (item: CodeType) => {
    setIsOpenDeleteModal(true);
    setRowCodeInfo({ couponId: item.couponId, code: item.code });
  };

  const { item, className = '' } = props;

  const orderValueArray = useMemo(() => {
    return [
      { content: item.couponId },

      { content: item.code },

      { content: item.oneClaimPerUser ? 'True' : 'False' },

      { content: `${(item.discount * 100).toFixed(2)}%` },

      /* eslint-disable */
      { content: `${("0" + (new Date(new Date(item.expiresAt).getTime() + 24*60*60*1000).getMonth() + 1)).slice(-2) + "/" +
        ("0" + new Date(new Date(item.expiresAt).getTime() + 24*60*60*1000).getDate()).slice(-2) + "/" +
        new Date(new Date(item.expiresAt).getTime() + 24*60*60*1000).getFullYear()}` },

      {
        content: (
          <GhostButton className="secondary">
            <DeleteIcon color='error' onClick={() => onOpenDeleteRowCodeModal(item)} />
          </GhostButton>
        ),
      },

    ];
  }, [item]);

  return (
    <>
      <StyledTableBodyRow className={className}>
        {orderValueArray.map((item, index) => (
          <td key={index}>
            <div>
              {item.content}
            </div>
          </td>
        ))}
      </StyledTableBodyRow>
      {
        isOpenDeleteModal ? (
          <tr>
            <td>
              <DeleteModal
                onClick={onChangeCode}
                onCloseModal={onClickCancelChangeRowCodeModal}
                rowInfo={rowCodeInfo}
              />
            </td>
          </tr>
        ) : null
      }
    </>
  );
};

export default TableBodyRowCodeAdmin;
