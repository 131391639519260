import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FilterType } from '../../types/filterTypes';
import type { UserSliceType } from '../../types/userTypes';
import thunk from './userThunk';
import { couponStatusFilter } from '../code/codeAdminTableHead';

const initialState: UserSliceType = {
  couponStatusFilter,
  user: {
    balance: 0,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    userId: 0,
    lastActivity: null,
    email: '',
    avatar: null,
    firstName: '',
    lastName: '',
    status: '',
    isSuperUser: false,
    fullName: '',
    address: '',
    phone: '',
    city: '',
    state: '',
    zipCode: '',
    code: '',
    agreement: false,
    rowCost: 0,
    llcCost: 0,
    discount: 0,
    isBlacklistFree: false,
  },

  filter: {
    findString: '',
    startDate: null,
    endDate: null,
    page: '1',
    sortField: '',
    sortDirect: '',
    status: '',
    perPage: '7',
  },

  bulks: {
    countFile: 0,
    countPending: 0,
  },

  users: [],

  codes: [],

  coupons: [],

  options: {
    page: 1,
    perPage: 7,
    totalPages: 0,
    totalRecords: 0,
  },

  addition: {
    errorMessage: '',
    isLoading: false,
    firstTimeAlert: false,
  },

  payments: [],

  cards: [],

  amountPayment: 0,

  customer: {
    invoice_settings: {
      default_payment_method: '',
    },
  },

  promoterAuth: {
    accessToken: null,
    expiresIn: null,
  },
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    changeUserFilter: (
      state,
      action: PayloadAction<{ title: string; value: FilterType[keyof FilterType] }>,
    ) => {
      if (!action) {
        return;
      }
      if (action.payload.title in state.filter) {
        (
          state.filter[action.payload.title as keyof FilterType] as FilterType[keyof FilterType]
        ) = action.payload.value;
      }
    },
    setAmountPayment: (state, action: PayloadAction<number>) => {
      if (!action) {
        return;
      }
      state.amountPayment = action.payload;
    },
    unsetAmountPayment: (state) => {
      state.amountPayment = initialState.amountPayment;
    },
    unsetBulkCount: (state) => {
      state.bulks = initialState.bulks;
    },
    setLoading: (state) => {
      state.addition.isLoading = true;
    },
    unsetLoading: (state) => {
      state.addition.isLoading = false;
    },
    logout: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunk.loginByPassEmail.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.user = payload;
    });

    builder.addCase(thunk.loginByToken.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.user = payload;
    });

    builder.addCase(thunk.signUp.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.user = payload;
    });

    builder.addCase(thunk.changeInfoUser.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.user = payload;
    });

    builder.addCase(thunk.getOrderCount.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.bulks = payload;
      state.addition.firstTimeAlert = payload.countFile === 0;
    });

    builder.addCase(thunk.getAdminOrderCount.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.bulks = payload;
    });

    builder.addCase(thunk.getAdminUserCount.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.bulks.countFile = payload.countUsers;
      state.bulks.countPending = 0;
    });

    builder.addCase(thunk.getAdminUsers.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.users = payload.users;
      state.options = payload.options;
    });

    builder.addCase(thunk.getPayments.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.payments = payload.files;
      state.options = payload.options;
    });

    builder.addCase(thunk.getPromoterAuth.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.promoterAuth.accessToken = payload.access_token;
      state.promoterAuth.expiresIn = payload.expires_in;
    });

    builder.addCase(thunk.getCards.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }

      state.cards = payload
        .filter((item) => item.type === 'card')
        .sort((item) => (
          item.id !== state.customer.invoice_settings.default_payment_method ? 1 : -1
        ));
    });

    builder.addCase(thunk.getCustomer.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }

      state.customer = payload;
    });

    builder.addCase(thunk.setDefaultPM.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }

      state.customer = payload;
    });

    builder.addCase(thunk.getAdminCodeCount.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.bulks.countFile = payload.countCodes;
      state.bulks.countPending = 0;
    });

    builder.addCase(thunk.getAdminCodes.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.codes = payload.codes;
      state.options = payload.options;
    });

    builder.addCase(thunk.getAdminCoupons.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
      state.coupons = payload.coupons;
      state.options = payload.options;
      state.bulks.countFile = payload.options?.totalRecords || 0;
      state.bulks.countPending = 0;
    });

    builder.addCase(thunk.deleteCode.fulfilled, (state, { payload }) => {
      if (!payload) {
        return state;
      }
    });
  },
});

export const {
  changeUserFilter,
  setLoading,
  unsetLoading,
  logout,
  setAmountPayment,
  unsetAmountPayment,
  unsetBulkCount,
} = userSlice.actions;
export default userSlice.reducer;
