import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { adminAPI, authAPI, paymentApi, userAPI, codeAPI, couponAPI } from '../../api/services/userApi';
import type { FilterType } from '../../types/filterTypes';
import type { ChangeCostType, ChangeInfoType, LoginType, ResetPasswordFirstPartType, ResetPasswordSecondPartType, ResetPasswordThirdPartType } from '../../types/userTypes';
import { getCurrentFilter } from '../../utils/changeFilterRequestHelper';
import { errorReturn } from '../../utils/errorReturnHelper';
import { setLoading, unsetLoading } from './userSlice';
import type { AddCodeType, ChangeCodeType } from '../../types/codeTypes';
import type { AddCouponType, ChangeCouponType } from '../../types/couponTypes';

const loginByPassEmail = createAsyncThunk(
  'user/login',
  async (values: LoginType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await authAPI.login(values);
      if (res.status === StatusCodes.OK) {
        if (res.data.data?.token) {
          localStorage.setItem('token', res.data.data.token);
        }
        return res.data.data?.user;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const loginByToken = createAsyncThunk(
  'user/login-by-token',
  async (value, { rejectWithValue }) => {
    try {
      const res = await userAPI.loginByToken();
      if (res.status === StatusCodes.OK) {
        return res.data.data?.user;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getOrderCount = createAsyncThunk(
  'user/get-order-count',
  async (value, { rejectWithValue }) => {
    try {
      const res = await userAPI.getOrderCount();
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getAdminOrderCount = createAsyncThunk(
  'admin/get-order-count',
  async (value, { rejectWithValue }) => {
    try {
      const res = await adminAPI.getOrderCount();
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getAdminUserCount = createAsyncThunk(
  'admin/get-user-count',
  async (value, { rejectWithValue }) => {
    try {
      const res = await adminAPI.getUserCount();
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getAdminUsers = createAsyncThunk(
  'admin/get-users',
  async (value: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(value);
      const res = await adminAPI.getUsers(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const getPayments = createAsyncThunk(
  'user/get-payment',
  async (value: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(value);
      const res = await userAPI.getPayments(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const getPromoterAuth = createAsyncThunk(
  'user/promoter-auth',
  async (value, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await userAPI.getPromoterAuthToken();
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const getCards = createAsyncThunk(
  'user/get-cards',
  async (value, { rejectWithValue }) => {
    try {
      const res = await paymentApi.getCards();
      if (res.status === StatusCodes.OK) {
        return res.data.data?.paymentMethods.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getCustomer = createAsyncThunk(
  'user/get-customer',
  async (value, { rejectWithValue }) => {
    try {
      const res = await paymentApi.getCustomer();
      if (res.status === StatusCodes.OK) {
        return res.data.data?.customer;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const setDefaultPM = createAsyncThunk(
  'user/set-default-pm',
  async (payMethod: string, { rejectWithValue }) => {
    try {
      const res = await paymentApi.setDefaultPM(payMethod);
      if (res.status === StatusCodes.OK) {
        return res.data.data?.customer;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const changeCost = createAsyncThunk(
  'admin/change-cost',
  async (value: ChangeCostType, { rejectWithValue }) => {
    try {
      const res = await adminAPI.changeCost(value);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const signUp = createAsyncThunk(
  'user/sign-up',
  async (values: LoginType, { rejectWithValue }) => {
    try {
      const res = await authAPI.signUp(values);
      if (res.status === StatusCodes.OK) {
        if (res.data.data?.token) {
          localStorage.setItem('token', res.data.data.token);
        }
        return res.data.data?.user;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const changeInfoUser = createAsyncThunk(
  'user/change-info',
  async (values: ChangeInfoType, { rejectWithValue }) => {
    try {
      const res = await userAPI.changeInfoUser(values);
      if (res.status === StatusCodes.OK) {
        return res.data.data?.result;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const resetPasswordFirstPart = createAsyncThunk(
  'user/reset-password-first',
  async (values: ResetPasswordFirstPartType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await authAPI.sendCode(values);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const resetPasswordSecondPart = createAsyncThunk(
  'user/reset-password-second',
  async (values: ResetPasswordSecondPartType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await authAPI.resetPassword(values);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const resetPasswordThirdPart = createAsyncThunk(
  'user/reset-password-third',
  async (values: ResetPasswordThirdPartType, { rejectWithValue }) => {
    try {
      const res = await authAPI.changeOldPassword(values);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const editPassword = createAsyncThunk(
  'user/edit-password',
  async (values: ResetPasswordThirdPartType, { rejectWithValue }) => {
    try {
      const res = await userAPI.editPassword(values);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getAdminCodeCount = createAsyncThunk(
  'admin/get-code-count',
  async (value, { rejectWithValue }) => {
    try {
      const res = await codeAPI.getCodeCount();
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    }
  },
);

const getAdminCodes = createAsyncThunk(
  'admin/get-codes',
  async (value: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(value);
      const res = await codeAPI.getCodes(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const getAdminCoupons = createAsyncThunk(
  'admin/get-coupons',
  async (value: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(value);
      const res = await couponAPI.getCoupons(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const deleteCoupon = createAsyncThunk(
  'admin/delete-coupon',
  async (value: ChangeCouponType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await couponAPI.deleteCoupon(value);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const deleteCode = createAsyncThunk(
  'admin/delete-code',
  async (value: ChangeCodeType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await codeAPI.deleteCode(value);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const addCode = createAsyncThunk(
  'admin/add-code',
  async (value: AddCodeType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await codeAPI.addCode(value);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const addCoupon = createAsyncThunk(
  'admin/add-coupon',
  async (value: AddCouponType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await couponAPI.addCoupon(value);
      if (res.status === StatusCodes.OK) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

export default {
  loginByPassEmail,
  loginByToken,
  getOrderCount,
  getAdminOrderCount,
  getAdminUserCount,
  getAdminUsers,
  signUp,
  changeInfoUser,
  resetPasswordFirstPart,
  resetPasswordSecondPart,
  resetPasswordThirdPart,
  editPassword,
  changeCost,
  getPayments,
  getCards,
  getCustomer,
  setDefaultPM,
  getAdminCodes,
  getAdminCodeCount,
  deleteCode,
  addCode,
  getPromoterAuth,
  getAdminCoupons,
  deleteCoupon,
  addCoupon,
};
