import React from 'react';
import StyledDropDownListStatus from './DropDownListStatus.styles';
import type { FilterType } from '../../types/filterTypes';
import { useAppSelector } from '../../store/hooks';
import Checkbox from '../Checkbox';
import { useLocationName } from '../../utils/checkLocationHelper';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
};

const DropDownListStatus: React.FC<PropsType> = (props) => {
  const statusFilterArray = useAppSelector((state) => state.orderSlice.orderStatusFilter);
  const couponFilterArray = useAppSelector((state) => state.userSlice.couponStatusFilter);
  const newStatusFilter = props.filter.status.split(',');
  const locationName = useLocationName();
  const isCouponPage = locationName === 'coupons';

  const filters = isCouponPage ? couponFilterArray : statusFilterArray;

  const onChangeSelect = (item: string) => {
    let newArr = '';
    if (item) {
      if (props.filter.status.includes(item)) {
        newStatusFilter.splice(newStatusFilter.indexOf(item), 1);
        newArr = newStatusFilter.join();
      } else if (props.filter.status.length === 0) {
        newArr = `${item}`;
      } else {
        newArr = `${props.filter.status},${item}`;
      }
    }
    props.setFilter('status', newArr);
  };

  const checkListItem = (value: string, index: number) => {
    if (index) {
      return props.filter.status.includes(`${value}`);
    }
    return (props.filter.status === value);
  };

  return (
    <StyledDropDownListStatus>
      {
        filters.length
          ? filters.map((item, index) => (
            <label key={index} className="styled-drop-down-list__item">
              <Checkbox
                checked={checkListItem(item.value, index)}
                onChange={() => onChangeSelect(item.value)}
              />
              <p className="text-4">{item.title}</p>
            </label>
          )) : null
      }
    </StyledDropDownListStatus>
  );
};

export default DropDownListStatus;
