import * as yup from 'yup';

const formErrorTexts = {
  password: {
    min: 'The password is too short',
    matches: 'The password must consist of numbers and latin characters',
    required: 'Enter your password',
  },
  email: {
    email: 'Invalid email',
    required: 'Enter your email',
  },
  phone: {
    matches: 'The phone must consist of numbers',
  },
  newPassword: {
    min: 'The password is too short',
    matches: 'The password must consist of numbers and latin characters',
    required: 'Enter your new password',
  },
  repeatNewPassword: {
    oneOf: 'Both password need to be the same',
    required: 'Repeat your password without errors',
  },
  agreement: {
    oneOf: 'Field must be checked',
  },
  code: {
    required: 'Enter code',
  },
  rowCost: {
    required: 'Enter price',
    moreThan: 'Price must be more than 0',
  },

  amount: {
    required: 'Enter amount',
    moreThan: 'The value must be more or equal to $10',
  },

  discountCode: {
    min: 'The discount code is too short',
    matches: 'The discount code must consist of numbers and latin characters',
    required: 'Enter your discount code',
  },

  discount: {
    moreThan: 'Discount must be more than 0',
    max: 'Your discount exceeds the maximum value allowed',
    required: 'Enter your discount',
  },

  expires_at: {
    required: 'Enter your discount code expiration date',
  },
};

export const codeSchema = yup.object().shape({
  code: yup.string()
    .min(4, formErrorTexts.discountCode.min)
    .matches(/^[a-zA-Z0-9-_]{4,}$/, formErrorTexts.discountCode.matches)
    .required(formErrorTexts.discountCode.required),
  discount: yup
    .number()
    .positive()
    .moreThan(0, formErrorTexts.discount.moreThan)
    .max(70, formErrorTexts.discount.max)
    .required(formErrorTexts.discount.required),
  expires_at: yup
    .date()
    .required(formErrorTexts.expires_at.required),
});

export const loginSchema = yup.object().shape({
  password: yup.string()
    .min(8, formErrorTexts.password.min)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_+!@#$%^&*]{8,}$/, formErrorTexts.password.matches)
    .required(formErrorTexts.password.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
});

export const userSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  address: yup.string(),
  phone: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup.string(),
});

export const signupSchema = yup.object().shape({
  password: yup.string()
    .min(8, formErrorTexts.password.min)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_+!@#$%^&*]{8,}$/, formErrorTexts.password.matches)
    .required(formErrorTexts.password.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
  agreement: yup.boolean().oneOf([true], formErrorTexts.agreement.oneOf).required(),
});

export const resetPassEmailSchema = yup.object().shape({
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
});

export const resetPassCodeSchema = yup.object().shape({
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
  code: yup.string().required(formErrorTexts.code.required),
});

export const rowCostSchema = yup.object().shape({
  rowCost: yup
    .number()
    .positive()
    .moreThan(0, formErrorTexts.rowCost.moreThan)
    .required(formErrorTexts.rowCost.required),
});

export const amountSchema = yup.object().shape({
  amount: yup
    .number()
    .positive()
    .moreThan(9.99, formErrorTexts.amount.moreThan)
    .required(formErrorTexts.amount.required),
});

export const resetPassSchema = yup.object().shape({
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
  newPassword: yup.string()
    .min(8, formErrorTexts.newPassword.min)
    .matches(/[a-zA-Z]/, formErrorTexts.newPassword.matches)
    .matches(/[0-9]/, formErrorTexts.newPassword.matches)
    .matches(/^[a-zA-Z0-9-_+!@#$%^&*]{8,}$/, formErrorTexts.newPassword.matches)
    .required(formErrorTexts.newPassword.required),
  repeatNewPassword: yup.string()
    .when('newPassword', {
      is: (val: string) => (!!(val && val.length > 0)),
      then: yup.string().oneOf(
        [yup.ref('newPassword')],
        formErrorTexts.repeatNewPassword.oneOf,
      ),
    })
    .required(formErrorTexts.repeatNewPassword.required),
});

export const resetPassByUserSchema = yup.object().shape({
  currentPassword: yup.string()
    .min(8, formErrorTexts.password.min)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_+!@#$%^&*]{8,}$/, formErrorTexts.password.matches)
    .required(formErrorTexts.password.required),
  newPassword: yup.string()
    .min(8, formErrorTexts.newPassword.min)
    .matches(/[a-zA-Z]/, formErrorTexts.newPassword.matches)
    .matches(/[0-9]/, formErrorTexts.newPassword.matches)
    .matches(/^[a-zA-Z0-9-_+!@#$%^&*]{8,}$/, formErrorTexts.newPassword.matches)
    .required(formErrorTexts.newPassword.required),
  repeatNewPassword: yup.string()
    .when('newPassword', {
      is: (val: string) => (!!(val && val.length > 0)),
      then: yup.string().oneOf(
        [yup.ref('newPassword')],
        formErrorTexts.repeatNewPassword.oneOf,
      ),
    })
    .required(formErrorTexts.repeatNewPassword.required),
});

export const billingSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  zip: yup.string().required('ZIP code is required'),
  address: yup.string().required('Address is required'),
  orderNumber: yup.number().typeError('Order number must be a number').required('Order number is required'),
  paymentMethod: yup.string().required('Payment method is required'),
});

export const addCouponModalSchema = yup.object({
  code: yup.string()
    .required('Coupon code is required')
    .matches(/^\S*$/, 'Spaces are not allowed in the coupon code'),
  percent_off: yup.number()
    .min(10, 'Discount must be between 10 and 100')
    .max(100, 'Discount must be between 10 and 100')
    .required('Discount percentage is required'),
  expires_at: yup.string()
    .test(
      'is-future-date',
      'Expiration date must be greater than today',
      (value) => {
        if (!value) return false;
        const inputDate = new Date(value);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return inputDate > today;
      },
    )
    .required('Expiration date is required'),
  products: yup.array().min(1, 'At least one product must be selected').required('At least one product is required'),
  max_redemptions: yup.number()
    .when('isMaxRedemption', {
      is: true,
      then: yup.number().min(1, 'Max redemptions must be a positive number').required('Max redemptions is required'),
    })
    .notRequired(),
  duration: yup.string().oneOf(['once', 'forever', 'repeating'], 'Invalid duration value').required('Duration is required'),
  duration_in_months: yup.number()
    .when('duration', {
      is: 'repeating',
      then: yup.number()
        .min(1, 'Duration in months must be between 1 and 12')
        .max(12, 'Duration in months must be between 1 and 12')
        .required('Duration in months is required'),
    })
    .notRequired(),
});
